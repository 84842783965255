<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="div" class="loosethread">
      <header class="loosethread__header">
        <h3 class="loosethread__header_title">
          {{ loosethread.id ? "Edit" : "Add" }} Loose Thread
        </h3>
        <div class="loosethread__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete"
            @click="$emit('removeItem', loosethread)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid"
            @click="saveLooseThread"
          ></BaseButton>
        </div>
      </header>
      <div class="form__row">
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Title"
            :required="true"
            :validateError="errors[0] || serverError.title"
            v-model="loosethread.title"
            :element="loosethread.title"
            @input="loosethread.title = $event"
          ></BaseInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="form__row_col form__row_col--side"
          :rules="{ required: true }"
        >
          <BaseSelect
            placeholder="Type"
            v-model="loosethread.type"
            :selected="loosethread.type"
            :items="looseThreads"
            :required="true"
            @input="loosethread.type = $event.id"
          ></BaseSelect>
        </ValidationProvider>
      </div>
      <div class="form__row">
        <div class="form__row_col">
          <Editor
            placeholder="Description"
            :value="loosethread.description"
            @input="loosethread.description = $event"
            v-model="loosethread.description"
          ></Editor>
        </div>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import Editor from "@/components/inputs/Editor.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    BaseButton,
    BaseInput,
    BaseSelect,
    Editor,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      loosethread: {
        title: "",
        type: "",
        description: "",
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.loosethread = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("general", {
      looseThreads: "getLooseThreads",
    }),
    ...mapGetters("looseThreads", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    loosethread: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("events", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.saveLooseThread() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveLooseThread() {
      let data = {
        ...this.loosethread,
      };
      if (this.loosethread.id) {
        this.$emit("updateItem", { data });
      } else {
        this.$emit("createItem", { data });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loosethread {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>