<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Content</h1>
      <div class="page__header-search">
        <BaseSelect
          class="form-control--grey form-control--xs"
          placeholder="Search by"
          :selected="searchType"
          :items="searchTypes"
          @input="serachingType"
        ></BaseSelect>
        <BaseInput
          class="form-control--grey form-control--xs"
          style="max-width: 65rem"
          placeholder="Search"
          :icon="true"
          :timeout="true"
          :element="tableData.search"
          @input="searchItems"
        >
          <template #icon>
            <IconSearch color="#000" height="16" width="16"></IconSearch>
          </template>
        </BaseInput>
      </div>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <IconButton
          class="button--admin button--auto button--outline-grey button--ml-15"
          text="add loose thread"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <LooseThread
      v-if="modal"
      :modal="modal"
      :item="item"
      @hideModal="modal = false"
      @createItem="createItem"
      @updateItem="updateItem"
      @removeItem="confirmRemoveItem"
      @updateTag="apiGetFiltersBlock(filterBlock)"
    ></LooseThread>
    <OrderModal
      v-if="orderModal"
      :modal="orderModal"
      :items="items"
      :orderType="orderType"
      :loading="changesLoading"
      @reorder="reorderItems"
      @hideModal="orderModal = false"
    ></OrderModal>
  </div>
</template>
<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import LooseThread from "./LooseThread.vue";
import OrderModal from "./OrderModal.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    IconSearch,
    HorisontalTabsList,
    IconButton,
    IconPlusBold,
    Table,
    LooseThread,
    OrderModal,
    BaseSelect,
  },
  data() {
    return {
      tabs: [
        {
          title: "In The News",
          path: "/admin/content/in-the-news",
          key: "in-the-news",
        },
        {
          title: "Press Releases",
          path: "/admin/content/press-releases",
          key: "pressreleases",
        },
        {
          title: "Lives Well Lived",
          path: "/admin/content/lives-well-lived",
          key: "memoriams",
        },
        {
          title: "Loose Threads",
          path: "/admin/content/loose-threads",
          key: "loose-threads",
        },
        {
          title: "Art Advisory",
          path: "/admin/content/art-advisory",
          key: "art-advisory",
        },
        {
          title: "Tags",
          path: "/admin/content/tags",
          key: "tags",
        },
      ],
      modal: false,
      orderType: null,
      orderModal: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
        types: "",
        search: "",
        search_type: "",
      },
      searchTypes: [
        {
          id: "title",
          title: "Title",
        },
        {
          id: "type",
          title: "Type",
        },
      ],
      searchType: "title",
    };
  },
  async created() {
    await this.apiGetLooseThreads(this.tableData);
    this.initialItem();
  },
  computed: {
    ...mapGetters("looseThreads", {
      items: "getItems",
      headerItems: "getItemsHeaders",
      item: "getItem",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialItem();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("looseThreads", [
      "apiGetLooseThreads",
      "apiGetLooseThread",
      "apiPostLooseThread",
      "apiPutLooseThread",
      "apiDeleteLooseThread",
      "apiPutChoseOrder",
    ]),
    ...mapMutations("looseThreads", ["initialItem", "setError"]),
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetLooseThreads(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    serachingType(value) {
      this.searchType = value.id;
      this.searchItems(this.tableData.search);
    },
    async searchItems(val) {
      if (this.tableData.search != val || this.tableData.search_type != this.searchType) {
        let data = { ...this.tableData, page: 1, search: val, search_type: this.searchType };
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetLooseThread(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemoveItem(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "reorder": {
          this.orderType = item.orderType;
          this.orderModal = true;
          break;
        }
      }
    },

    async createItem({ data, imageForRemove }) {
      let result = await this.apiPostLooseThread(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        /*if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }*/
      }
    },
    async updateItem({ data, imageForRemove }) {
      let result = await this.apiPutLooseThread(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        /*if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }*/
      }
    },
    confirmRemoveItem(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeItem(item.id);
        }
      });
    },
    async removeItem(id) {
      let result = await this.apiDeleteLooseThread(id);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },

    async reorderItems(items) {
      let data = { ...this.tableData, page: 1 };
      let result = await this.apiPutChoseOrder(items);
      if (result) {
        let success = await this.apiGetLooseThreads(data);
        if (success) {
          this.tableData = data;
        }
        this.orderModal = false;
        this.orderType = null;
      }
    },
  },
};
</script>

